export const FuneralHomeTestimonials = [
  {
    review:
      "Their technology is far superior than anything else that is out there right now. They have the heart of a funeral director with the capabilities of an IT director.",
    // author: "Jon Kolssak — Kolssak Funeral Home",
  },
  {
    review:
      "Nothing matches Afterword in the funeral industry. They may say they can do the things, but they definitely cannot deliver the way Effie & Zack and Afterword can.",
    // author: "Jon Kolssak — Kolssak Funeral Home",
  },
  {
    review:
      "This might be the best thing I've ever seen in funeral service. I am so thankful that you have entered our world.",
    // author: "Jon Kolssak — Kolssak Funeral Home",
  },
  {
    review:
      "Afterword has created an interface that is easy to use and has proven to be a great benefit to the families we serve. Their knowledge and expertise help us deliver a seamless experience for those navigating a difficult time.",
    // author: "CJFH",
  },
];
